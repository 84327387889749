@charset "UTF-8";
@import 'bourbon/bourbon';
@import 'neat/neat';

* {
	background-color: transparent !important;
	color: black;
}

body{

}

.header, .footer,
.section-top-image-front,
.section-services,
.section-filter-profiles,
.section-search-and-filter-publications,
.section-front-statistics,
.submenu,
.section-video-text,
.pagination{ // always hide
	display: none;

}

.section-top-image{
	background: #fff none !important;
	margin-top: -450px;
	.container{
		.highlight{
			max-width: none !important;
			&--large{
				padding: 0;
			}
		}
		/*ul*/.breadcrumbs{
			display: none;
		}
		h1{
			font-size: 30px !important;
		}
	}
}

.publicationpage, .profilepage{
	.section-top-image{
		display: none;
	}
}

.column-two{
	margin-top: 40px;
	img{
		display: none;
	}
}

.section-contacts-slider{
	ul{
		li{
			display: none !important;
			&:first-child{
				display: block !important;
			}
		}
	}
}

body.modal-open{
	min-height: 100% !important;
	overflow: visible !important;

	.column-one,
	.column-two,
	.section-top-image {
		opacity: 0 !important;
		display: none !important;
	}
}

.modal {
	background: #fff !important;

	&.open {
		position: relative;
	}

	/*a*/.modal-close,
	.modal-print {
		display: none;
	}
	&__content {
		background: #fff !important;
		overflow-y: visible;
		.modal-text{
			background: #fff !important;
			max-width: none !important;
			padding: 50px 0 0;

			ul {
				margin-left: 20px !important;
				li {
					list-style-type: disc;
					&::before {
						content: none;
					}
				}
			}
		}
	}
}

.section-list-publications{
	.highlight{
		display: none;
	}
}

.section-services-landingpage{
	.service-container{
		a{
			background-color: #eaeaea !important;
			div{
				background-color: #eaeaea !important;
			}
		}
	}
}



// CONTACT PAGE

body.contactpage{
	text-align: center !important;
	.section-onecolumn-text{
		padding: 40px 0 !important;
	}
	.cities{
		a{
			background: none;
			display: none;

			&.active{
				display: block !important;
				float: none !important;
				width: 100% !important;
				h1, h2{
					color: #000 !important;
					font-size: 15px !important;
				}
				.text{
					padding: 0 !important;
					position: static;
				}
			}
			.margin{
				margin-top: 10%;
			}
		}
	}
	.text-info{
		float: none !important;
		padding: 0 !important;
		width: 100% !important;
	}
	.map-container, .zoom{
		display: none !important;
	}
}

.delayedView{
	&.hidden{
		opacity: 1 !important;
		@include transform(translateY(0px));
	}
}
